@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh !important;
    overflow: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.form-group {
    margin-bottom: 12px;
}

.rjsf {
    padding: 12px;
}

.thankyoupage {
    text-align: center;
}

#root {
    height: 100%;
}

.section-wrapper{
    position:relative;
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.card {
    padding: 24px;
    max-width: 960px;
    height: 100% !important;
    overflow-y: auto;
    margin: auto;
    /* margin-top: 24px !important; */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 0 !important;
    scrollbar-width: thin;
    scrollbar-color: #333333 #FFC300;
}

.card-login{
    height: 400px !important;
    width: 600px !important;
    border-radius: 4px !important;
}

#toast-warning{
    visibility: hidden;
    opacity: 0;
    transition: opacity 250ms ease-in, visibility 0ms ease-in 250ms;
}

#toast-warning.show{
    visibility: visible;
    opacity: 1;
    transition: opacity 250ms ease-in, visibility 0ms ease-in 0ms;
}

.control-label{
    font-weight: bold;
}

.hidden-label label.control-label {
    display: none;
}

.btn {
    width: 100%;
}

.btn-add {
    width: 100%;
}

.btn-group {
    margin-bottom: 12px;
}

legend {
    margin-top: 24px;
    font-weight: bold;
    margin-bottom: 12px;
}

.array-item-list {
    border: 1px solid #CCC;
    padding: 12px;
    margin-bottom: 24px;
    /* background-color: #FAFAFA; */
}

.array-item {
    margin-bottom: 24px;
    padding-bottom: 12px;
    border-bottom: 1px dashed #CCC;
}

.array-item:last-child {
    margin-bottom: 0;
    border-bottom: 0;
}

.glyphicon {
    font-style: normal;
    font-family: 'FontAwesome';
}

.glyphicon-plus::after {
    content: '\f067';
}

.glyphicon-arrow-down::after {
    content: '\f063';
}

.glyphicon-arrow-up::after {
    content: '\f062';
}

.glyphicon-remove::after {
    content: '\f00d';
}
